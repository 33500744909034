import { Component, OnInit } from '@angular/core';
import { ServicioService } from '../servicio.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.css']
})
export class DatosPersonalesComponent implements OnInit {

  private count: number;
  playerName:string;
  peso:number;
  altura: number;

  constructor(private servicio: ServicioService) { }
 ngOnInit(){}
 
onSubmit() {
  this.servicio.imc=this.peso*this.altura;
  console.log(this.servicio.imc);
}
}
