import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { ServicioService } from '../app/servicio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    message$: any;
    subscription: Subscription;
    private count: number;

    constructor(private messageService: ServicioService) {
        // subscribe to home component messagess
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    ngOnInit(){
      };
    }

  
