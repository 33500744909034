import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {UserDB} from '../../user/user.component';

export interface CodeDB { code: string; isAssigned: boolean; reto: string; }

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.css']
})
export class CodesComponent implements OnInit {
  codesForm: FormGroup;
  retos: string[];
  error: string = null;
  codes: Observable<CodeDB[]>;

  constructor(private fb: FormBuilder, private firestore: AngularFirestore, private spinner: NgxSpinnerService) {
    this.codes = this.firestore.collection<CodeDB>('codes', ref => ref.where('reto', '==', 'Reto 1')).valueChanges();
  }

  ngOnInit() {
    this.retos = environment.retos;
    this.codesForm = this.fb.group({
      retoSelect: ['Reto 1']
    });
  }

  async onGenerateCode() {
    this.spinner.show();

    try {
      const code: string = this.getCode();
      await this.firestore.collection('codes').doc(code).set({
        code,
        isAssigned: false,
        reto: this.codesForm.controls['retoSelect'].value
      });
    } catch (error) {
      console.log(error);
      this.error = 'Ha ocurrido un error al generar el código.';
    } finally {
      this.spinner.hide();
    }
  }

  async onDelete(code: CodeDB) {
    await this.firestore.doc(`codes/${code.code}`).delete();
    if (code.isAssigned) {
      this.firestore.collection<UserDB>('users', ref => ref.where('codes', 'array-contains', code.code)).valueChanges().subscribe(
        async (users: UserDB[]) => {
          await this.firestore.doc(`users/${users[0].id}`).update({
            codes: []
          });
        }
      );
    }
  }

  onChange() {
    this.codes = this.firestore.collection<CodeDB>('codes', ref =>
      ref.where('reto', '==', this.codesForm.controls['retoSelect'].value)
    ).valueChanges();
  }

  getCode(): string {
    let pin_number: string = Number(new Date()).toString();

    pin_number = pin_number.substr(3);

    return pin_number.charAt(0) === '0'
      ?
      pin_number.replace('0', '1')
      : pin_number;
  }
}
