import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {NgForm} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  error: string = null;
  code: string = null;
  email: string = null;

  constructor(private fireAuth: AngularFireAuth,
              private spinner: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: Params) => {
      this.code = params['oobCode'];
      this.email = params['email'];
    });
  }

  ngOnInit() {
  }

  async onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;

    this.spinner.show();

    try {
      await this.fireAuth.auth.sendPasswordResetEmail(email);
      this.router.navigate(['forgot'], { queryParams: { email }});
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          this.error = 'Email no valido.';
          break;
        case 'auth/user-not-found':
          this.error = 'Usuario no encontrado.';
          break;
        default:
          this.error = 'Ha ocurrido un error inesperado.';
      }
    } finally {
      this.spinner.hide();
    }
  }

  async onPasswordConfirm(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const password = form.value.password;

    this.spinner.show();

    try {
      await this.fireAuth.auth.confirmPasswordReset(this.code, password);
      this.router.navigate(['login']);
    } catch (error) {
      switch (error.code) {
        case 'auth/expired-action-code':
          this.error = 'Código expirado.';
          break;
        case 'auth/user-not-found':
          this.error = 'Usuario no encontrado.';
          break;
        case 'auth/weak-password':
          this.error = 'La contraseña debe tener almenos 6 caracteres.';
          break;
        case 'auth/user-disabled':
          this.error = 'El usuario ha sido desabilitado.';
          break;
        default:
          this.error = 'Ha ocurrido un error inesperado.';
      }
    } finally {
      this.spinner.hide();
    }
  }
}
