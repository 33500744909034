import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from 'firebase';
import {environment} from '../../environments/environment';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit, OnDestroy {
  @Input()
  isStatic: boolean;
  transparentNavBar = true;
  isSignedIn = false;
  isAdmin = false;

  constructor(private router: Router,
              private route: ActivatedRoute, private fireAuth: AngularFireAuth) {
    this.fireAuth.user.subscribe({
      next: (user: User) => {
        if (user) {
          this.isSignedIn = true;
          if (user.uid === environment.adminId) {
            this.isAdmin = true;
          }
        } else {
          this.isSignedIn = false;
        }
      }
    });
  }

  ngOnInit() {
    if (!this.isStatic) {
      window.addEventListener('scroll', this.scrollEvent, true);
    } else {
      this.transparentNavBar = false;
    }
  }

  ngOnDestroy() {
    if (!this.isStatic) {
      window.removeEventListener('scroll', this.scrollEvent, true);
    }
  }

  scrollEvent = (event: any): void => {
    this.transparentNavBar = !(window.pageYOffset > 200);
  };

  onLogin() {
    this.router.navigate(['login']);
  }

  onLogout() {
    this.fireAuth.auth.signOut();
    if (this.route.snapshot.url.length === 0) {
      window.location.reload();
    } else {
      this.router.navigate(['']);
    }
  }
}
