import {Component, OnInit} from '@angular/core';
import {Exercise, Exercises} from '../exercise/exercise.component';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

export interface Meal {
  image: string;
  ingredients: string[];
  preparation: string[];
  title: string;
}

export interface Meals {
  ayuno: Meal;
  desayuno: Meal;
  morning: Meal;
  almuerzo: Meal;
  tarde: Meal;
  cena: Meal;
}

@Component({
  selector: 'app-day-bottom',
  templateUrl: './day-bottom.component.html',
  styleUrls: ['./day-bottom.component.css']
})
export class DayBottomComponent implements OnInit {
  public dayNumber: number;
  public exercises: Exercise[];
  public mealsDB: Meals;
  public obsMeals: Observable<Meals>;
  public obsExercises: Observable<Exercises>;

  constructor(private _route: ActivatedRoute, private firestore: AngularFirestore) {}

  ngOnInit() {
    this.obsExercises = new Observable<Exercises>();
    this._route.paramMap
      .subscribe(
        (results: ParamMap) => {
          if (results.get('day')) {
            this.dayNumber = +results.get('day');
          } else {
            this.dayNumber = 0;
          }

          this.obsMeals = this.firestore.doc<Meals>(`meals/day-${this.dayNumber}`).valueChanges();
          // this.obsMeals = this.firestore.doc<Meals>(`meals/day-22`).valueChanges();
          this.obsMeals.subscribe((meals: Meals) => this.mealsDB = meals);

          if (this.dayNumber > 3) {
            this.obsExercises = this.firestore.doc<Exercises>(`exercises/day-${this.dayNumber}`).valueChanges();
            this.obsExercises.subscribe((exercises: Exercises) => this.exercises = exercises.exercise);
          }
        }
    );
  }
}
