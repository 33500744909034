import { Component, OnInit, Input } from '@angular/core';
import { ServicioService } from '../servicio.service';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.css']
})
export class AppButtonComponent implements OnInit {
  @Input() buttonConfig: any;
  constructor(private servicio: ServicioService) { }

  ngOnInit() {
  }

  onBtnClick() {
    this.servicio.genero=1;
  }
}
