import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgForm} from '@angular/forms';
import UserCredential = firebase.auth.UserCredential;
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {of, Subscription} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {User} from 'firebase';
import {UserDB} from '../user/user.component';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  error: string = null;
  watcher: Subscription;
  activeMediaQuery = '';
  isMobile = false;

  constructor(private firestore: AngularFirestore, private fireAuth: AngularFireAuth, private spinner: NgxSpinnerService, private router: Router, private mediaObserver: MediaObserver) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      this.isMobile = change.mqAlias == 'sm' || change.mqAlias == 'md' || change.mqAlias == 'xs';
      console.log(change);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }

  async onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;

    this.spinner.show();

    try {
      const user: UserCredential = await this.fireAuth.auth.signInWithEmailAndPassword(email, password);
      if (user.user.uid === environment.adminId) {
        this.router.navigate(['admin']);
      } else {
        this.firestore.doc<UserDB>(`users/${user.user.uid}`).valueChanges().subscribe({
          next: (user: UserDB | undefined) => {
            if (user !== undefined) {
              if (user.isActive) {
                this.router.navigate(['days']);
              }
            } else {
              this.router.navigate(['user']);
            }
          }
        });
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          this.error = 'Email no valido.';
          break;
        case 'auth/user-disabled':
          this.error = 'El usuario ha sido desabilitado.';
          break;
        case 'auth/user-not-found':
          this.error = 'Usuario no encontrado.';
          break;
        case 'auth/wrong-password':
          this.error = 'La contraseña es incorrecta.';
          break;
        default:
          this.error = 'Ha ocurrido un error inesperado.';
      }

      setTimeout(() => {
        this.error = null;
      }, 3000);

      form.resetForm();
    } finally {
      this.spinner.hide();
    }
  }

  onForgot() {
    this.router.navigate(['forgot']);
  }
}
