// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAoyMQGbjLaPccxuZEEjCkGjsIrha3empk',
    authDomain: 'test-reto-fit.firebaseapp.com',
    databaseURL: 'https://test-reto-fit.firebaseio.com',
    projectId: 'test-reto-fit',
    storageBucket: 'test-reto-fit.appspot.com',
    messagingSenderId: '231439300371',
    appId: '1:231439300371:web:04706391c6de0fe8ca4ce6',
    measurementId: 'G-RXWC0CM68H'
  },
  adminId: 'NVIj0vXOUJYhqHLMF6bPGzdxKTD3',
  retos: ['Reto 1']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
