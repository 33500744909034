import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { User } from 'firebase';
import { of, Subscription } from 'rxjs';
import { UserDB } from '../user/user.component';
import * as moment from 'moment';

@Component({
  selector: 'app-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.css']
})
export class DaysComponent implements OnInit, OnDestroy {

  public day: number;
  public userDb: UserDB;
  userSubscribe: Subscription;
  private user: AngularFirestoreDocument<UserDB>;

  constructor(
    private router: Router,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
  ) {
  }


  ngOnInit() {
    this.day = -1;
    this.userSubscribe = this.fireAuth.user.pipe(
      mergeMap((user: User) => {
        if (user) {
          this.user = this.firestore.doc<UserDB>(`users/${user.uid}`);
          return this.user.valueChanges();
        } else {
          this.router.navigate(['/']);
          return of(undefined);
        }
      })
    ).subscribe({
      next: async (user: UserDB | undefined) => {
        if (user !== undefined && user.isActive) {
          this.userDb = user;
          this.day = moment().startOf('day').diff(moment(user.activated * 1000).startOf('day'), 'days');
          if (this.day > 21) {
            await this.firestore.collection('users').doc(user.id).update({ estadoReto: 'FINALIZADO' });
            this.router.navigate(['/user']);
          } else {
            this.router.navigateByUrl(`/days/${this.day}/1`);
          }
        } else {
          this.router.navigate(['/user']);
        }
      }
    });
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
    this.userSubscribe = null;
    console.log('se cerro');
  }

}
