import { DialogUserComponent } from './../dialog-user/dialog-user.component';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';

export interface UserDB {
  id: string;
  email: string;
  isActive: boolean;
  name: string;
  phone: string;
  codes: string[];
  activated: number;
  paymentMethod: string;
  address: string;
  lastName: string;
  estadoReto: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  private user: AngularFirestoreDocument<UserDB>;
  private userDB: UserDB;
  isActivated = false;
  day: number;
  dayComplete: boolean = false;
  estadoReto: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private loadingService: NgxSpinnerService,
  ) {
    this.fireAuth.user.pipe(
      mergeMap((user: User) => {
        if (user) {
          this.user = this.firestore.doc<UserDB>(`users/${user.uid}`);
          return this.user.valueChanges();
        } else {
          this.router.navigate(['']);
          return of(undefined);
        }
      })
    ).subscribe({
      next: (user: UserDB | undefined) => {
        if (user !== undefined) {
          this.day = moment().startOf('day').diff(moment(user.activated * 1000).startOf('day'), 'days');
          this.estadoReto = user.estadoReto;
          this.isActivated = user.isActive;
          this.userDB = user;
          this.dayComplete = this.day > 21;
        }
      }
    });
  }

  ngOnInit() {
  }

  async repeatReto() {
    this.loadingService.show();
    const { name, email, phone } = { ...this.userDB };
    await this.firestore.collection('users').doc(this.userDB.id).update({
      estadoReto: 'INSCRITO',
    });
    try {
      await this.http.get(`https://rkc3e.sse.codesandbox.io/listen?email=${email}&name=${name}&phone=${phone}`).toPromise();
    } catch {

    }
    this.loadingService.hide();
    this.dialog.open(DialogUserComponent, {});
  }

}
