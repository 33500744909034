import {Component, Input, OnInit} from '@angular/core';
import {Exercise, Exercises} from '../exercise/exercise.component';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import {Meals} from '../day-bottom/day-bottom.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.css']
})
export class ExerciseInfoComponent implements OnInit {
  public exercise: Exercise;
  public obsExercises: Observable<Exercises>;

  constructor(private _route: ActivatedRoute, private firestore: AngularFirestore, private _router: Router) { }

  ngOnInit() {
    console.log("hola");
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const day: number = this._route.snapshot['_routerState'].url.split('/')[2];
        const exercise: number = this._route.snapshot['_routerState'].url.split('/')[3];

        if (day > 3 && exercise) {
          this.obsExercises = this.firestore.doc<Exercises>(`exercises/day-${day}`).valueChanges();
          this.obsExercises.subscribe((exercises: Exercises) => {
            this.exercise = exercises.exercise[exercise - 1];
          });
        }
      }
    });
    this._route.paramMap
      .subscribe(
        (results: ParamMap) => {
          const day: number = this._route.snapshot['_routerState'].url.split('/')[2];

          if (day > 3 && results.get('exercise')) {
            this.obsExercises = this.firestore.doc<Exercises>(`exercises/day-${day}`).valueChanges();
            // this.obsExercises = this.firestore.doc<Exercises>(`exercises/day-30`).valueChanges();
            this.obsExercises.subscribe((exercises: Exercises) => {
              this.exercise = exercises.exercise[+results.get('exercise') - 1];
            });
          }
        }
      );
  }

  public changeEventExercise(exercise: Exercise): void {
    exercise.showEvent = !exercise.showEvent;
  }
}
