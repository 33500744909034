import { Component, OnInit } from '@angular/core';
import { Observable, timer} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  private readonly router: Router;

  constructor( router: Router) {
    this.router = router;
  }

  public ngOnInit(): void {
    this.setCounter();
  }

  public setCounter(): void {
    const source: Observable<number> = timer(10000);
    source.subscribe(() => {
      void this.router.navigateByUrl('inicio');
      }
    );

  }

}
