import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {NgxSpinnerService} from 'ngx-spinner';
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import UserCredential = firebase.auth.UserCredential;
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-inscribete',
  templateUrl: './inscribete.component.html',
  styleUrls: ['./inscribete.component.css']
})
export class InscribeteComponent implements OnInit {
  public register: FormGroup;
  error: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private fireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private router: Router,
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private http: HttpClient) {
  }

  ngOnInit() {
    this.register = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      address: ['', [Validators.required]]
    });
  }

  onLogin() {
    this.router.navigate(['login']);
  }

  public retrieveErrors(formGroup: FormGroup): string {
    let message = '';

    Object.keys(formGroup.controls).forEach(
      (field: string[] | number[] | string) => {
        const control: AbstractControl | null = formGroup.get(field);

        control.markAsTouched({onlySelf: true});
        if (control.status === 'INVALID') {
          message += `${field as string}, `;
        }
      }
    );

    return message.slice(0, -2);
  }

  async onSubmit() {
    const errors: string = this.retrieveErrors(this.register);
    if (Boolean(errors)) {
      return;
    }

    const name = this.register.value.name;
    const lastName = this.register.value.lastName;
    const phone = this.register.value.phone;
    const email = this.register.value.email;
    const password = this.register.value.password;
    const address = this.register.value.address;
    const paymentMethod = this.route.snapshot.queryParamMap.get('payment') ? this.route.snapshot.queryParamMap.get('payment') : 'Tarjeta';

    this.spinner.show();

    try {
      const user: UserCredential = await this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
      await this.firestore.collection('users').doc(user.user.uid).set({
        email,
        name,
        phone,
        lastName,
        address,
        paymentMethod,
        isActive: false,
        id: user.user.uid,
        estadoReto:'INSCRITO',
      });

      await this.http.get(`https://lx07m.sse.codesandbox.io/listen?email=${email}&name=${name}&phone=${phone}`).toPromise();
      this.router.navigate(['user']);
    } catch (error) {
      if (error.status === 200) {
        this.router.navigate(['user']);
        return;
      }
      switch (error.code) {
        case 'auth/invalid-email':
          this.error = 'Email no valido.';
          break;
        case 'auth/email-already-in-use':
          this.error = 'El correo ingresado ya ha sido utlizado.';
          break;
        case 'auth/weak-password':
          this.error = 'La contraseña debe tener almenos 6 caracteres.';
          break;
        default:
          this.error = error.message;
      }

      if (error.name === 'FirebaseError') {
        await this.fireAuth.auth.currentUser.delete();
        this.error = 'Error al guardar el usuario intentelo nuevamente.';
      }

      setTimeout(() => {
        this.error = null;
      }, 5000);
    } finally {
      this.spinner.hide();
    }
  }
}
