import { Component, OnInit } from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  public timeLeft: moment.Duration;
  public daysLeft: string;
  public hoursLeft: string;
  public minutesLeft: string;
  public secondsLeft: string;
  public expirationDate: moment.Moment;

  constructor() { }

  ngOnInit() {
    this.expirationDate = moment().add(12, 'h');
    this.initCountdown();
    setInterval(() => this.initCountdown(), 1000);
  }

  public initCountdown(): void {
    this.timeLeft = moment.duration(this.expirationDate.diff(moment()));
    this.daysLeft = this.timeLeft.asDays() >= 0 ? this.timeLeft.asDays().toFixed() : '0';
    this.hoursLeft = this.timeLeft.hours() >= 0 ? this.timeLeft.hours().toFixed() : '0';
    this.minutesLeft = this.timeLeft.minutes() >=0 ? this.timeLeft.minutes().toFixed() : '0';
    this.secondsLeft = this.timeLeft.seconds() >= 0 ? this.timeLeft.seconds().toFixed() : '0';
  }
}
