import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {LoginComponent} from './login/login.component';
import {UserComponent} from './user/user.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {InscribeteComponent} from 'src/app/inscribete/inscribete.component';
import {BienvenidaComponent} from 'src/app/bienvenida/bienvenida.component';
import {PesoActualComponent} from 'src/app/peso-actual/peso-actual.component';
import {QuestionsComponent} from './questions/questions.component';
import {QuestionsETComponent} from './questions-et/questions-et.component';
import {QHowYouFeelComponent} from './q-how-you-feel/q-how-you-feel.component';
import {FaqComponent} from './faq/faq.component';
import {LoadingComponent} from './loading/loading.component';
import {DaysComponent} from './days/days.component';
import {PricesComponent} from './prices/prices.component';
import {DayBottomComponent} from './day-bottom/day-bottom.component';
import {ExerciseInfoComponent} from './exercise-info/exercise-info.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: QuestionsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'days', component: DaysComponent,
    children: [
      { path: ':day', component: DayBottomComponent,
        children: [
          { path: ':exercise', component: ExerciseInfoComponent }
        ]
      }
    ]
  },
  {path: 'p3', component: QHowYouFeelComponent},
  {path: 'p2', component: QuestionsETComponent},
  {path: 'bienvenida', component: BienvenidaComponent},
  {path: 'login', component: LoginComponent},
  {path: 'user', component: UserComponent},
  {path: 'forgot', component: ForgotPasswordComponent},
  {path: 'register', component: InscribeteComponent},
  {path: 'inicio', component: LandingPageComponent},
  {path: 'precios', component: PricesComponent},
  // {path: 'admin', component: AdminComponent,
  //   children: [
  //     { path: '', pathMatch: 'full', redirectTo: '/admin/users' },
  //     { path: 'users', component: UsersComponent },
  //     {
  //       path: 'codes',
  //       component: CodesComponent,
  //     }
  //   ]
  // },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {path: 'pesoactual', component: PesoActualComponent},
  {path: 'user', component: UserComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
