import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Chart} from 'chart.js';
import { R3TargetBinder } from '@angular/compiler';
import { ServicioService } from '../servicio.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  watcher: Subscription;
  activeMediaQuery = '';
  isMobile = false;
  imc:number;
  edadMet:number;
  edadMet2;
  imcExceso:number;
  factorX:number;
  calorias:number;
  imcTabla:string;
  valorChart:number;
  imcX:number;
  imcY:number;


title='dashboard';
  chart = []; // This will hold our chart info

  constructor(private router: Router, private mediaObserver: MediaObserver, private servicio: ServicioService, @Inject(DOCUMENT) document) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      this.isMobile = change.mqAlias == 'sm' || change.mqAlias == 'md' || change.mqAlias == 'xs';
    });
  }

  ngOnInit() {
    console.log(this.servicio.actividad);
    this.cargarIMC();
    this.cargarValorTablaIMC();
    this.graficarIMC();
    this.calcularEdadMetabolica();
    this.calcularCalorias();
  }

  //cargar el correspondiente al valor del IMC
  cargarValorTablaIMC(){
    if(this.imc<18.5){
      this.imcTabla="Peso Bajo";
      this.imcX=1;
      this.imcY=0;
    }
    else if(this.imc>=18.5 && this.imc<25){
      this.imcTabla="Peso Normal";
      this.imcX=3;
      this.imcY=4;
    }
    else if(this.imc>=25 && this.imc<27){
      this.imcTabla="Sobrepeso Grado I";
      this.imcX=4;
      this.imcY=4.5;
    }
    else if(this.imc>=27 && this.imc<30){
      this.imcTabla="Sobrepeso Grado II";
      this.imcX=6;
      this.imcY=6.5;
    }
    else if(this.imc>=30 && this.imc<35){
      this.imcTabla="Obesidad Tipo I";
      this.imcX=8;
      this.imcY=6.5;
    }
    else if(this.imc>=35 && this.imc<40){
      this.imcTabla="Obesidad Tipo II";
      this.imcX=9;
      this.imcY=7;
    }
    else if(this.imc>=40 && this.imc<50){
      this.imcTabla="Obesidad Mórbida";
      this.imcX=11;
      this.imcY=8;
    }
    else if(this.imc>50){
      this.imcTabla="Obesidad Extrema";
      this.imcX=12;
      this.imcY=9;
    }
  }

  //gráfico del IMC
  graficarIMC(){
    var canvas = <HTMLCanvasElement> document.getElementById("myChart");
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(1, "#7FFF00");
    gradientStroke.addColorStop(0, "#FF4500");

    this.chart = new Chart('myChart', {
      responsive: true,
      type:'bubble',
    data: {
      datasets: [{
        type: 'line',
        data: [{x:1,y:0}, {x:2,y:3},{x:3,y:4},{x:4,y:4.5},{x:5,y:5},{x:6,y:5.5},{x:7,y:6},{x:8,y:6.5},{x:9,y:7},{x:10,y:7.5},{x:11,y:8},{x:12,y:9},{x:13,y:12}],
        pointBorderColor:'rgb(0,0,0,0)',
        borderColor:gradientStroke,
        order:2
      },{
        borderColor:'rgb(1,1,1)',
        borderWidth:5,
        data:[{x:this.imcX, y:this.imcY, r:15}],

        order:1,
        backgroundColor:'#FFEFD5'
          }],
        backgroundColor:'rgb(1,1,1)'
      },
      options: {
        scales: {
          xAxes: [{
            display: false,
            ticks:{
              autoSkip:false
            },
            beginAtZero:false
          }],
          yAxes: [{
            display: false,
            autoSkip:false,
            beginAtZero:false
          }]
        },
        legend: {
          display: false
        }
      }
    });

  }

  //cálculo de la edad metabólica
  calcularEdadMetabolica(){

    this.imcExceso=this.servicio.imc-this.servicio.imcIdeal;
    this.factorX=this.imcExceso*this.servicio.indiceAct;
    this.factorX=Math.pow(Math.pow(this.factorX,2),0.5)*0.5;
    this.edadMet=Math.round(+this.servicio.edad+Math.round(+this.factorX));
  }

  //cargar valor del IMC
  cargarIMC(){
    this.imc=Math.round(this.servicio.imc);
  }

  //cálculo de las calorías
  calcularCalorias(){
    
    if(this.servicio.genero==1){
      this.edadMet2=(10*this.servicio.pesoActual)+(6.25*this.servicio.altura)-(5*this.servicio.edad)-161;
    }
    else{
    this.edadMet2=(10*this.servicio.pesoActual)+(6.25*this.servicio.altura)-(5*this.servicio.edad)+5;
    }



    this.calorias=Math.round(this.edadMet2*this.servicio.actividad*0.85);//this.servicio.actividad;
  }

  ngOnDestroy() {
    //this.watcher.unsubscribe();
  }

  onSignUp() {
    this.router.navigate(['/precios']);
  }


}
