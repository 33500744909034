import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ServicioService } from '../servicio.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-peso-actual',
  templateUrl: './peso-actual.component.html',
  styleUrls: ['./peso-actual.component.css']
})
export class PesoActualComponent implements OnInit {

  //parametros que vamos a leer del input
  pesoObjetivo:number;
  pesoActual: number;
  altura:number;
  edad: number;
  private readonly router: Router;

  constructor(private servicio: ServicioService, router: Router) {
    this.router = router;
    }

  ngOnInit() {
    console.log("actividad:",this.servicio.actividad);
  }

  //funcion para pasar todos los datos del input al servicio
  onClickData() {
    this.servicio.edad=this.edad;
    this.servicio.altura=this.altura;
    this.servicio.pesoActual=this.pesoActual;
    this.servicio.pesoObjetivo=this.pesoObjetivo;
    this.servicio.imc=(this.pesoActual/((this.altura/100)*(this.altura/100)));
    this.servicio.imcIdeal=21.7;
    console.log("IMC",this.servicio.imc);
    void this.router.navigateByUrl('loading');
  }

}
