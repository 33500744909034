import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goLanding(tipo: string): void {
    this.router.navigateByUrl(`/register?payment=${tipo}`);
  }
}
