import {Component, Input, OnInit} from '@angular/core';
import {Meal} from '../day-bottom/day-bottom.component';

@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.css']
})
export class MealComponent implements OnInit {
  @Input() public meal: Meal;

  public showEvent: boolean;

  constructor() {
    this.showEvent = false;
  }

  ngOnInit() {
  }

  public changeEvent(b: boolean): void {
    this.showEvent = b;
  }
}
