import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-day-top',
  templateUrl: './day-top.component.html',
  styleUrls: ['./day-top.component.css']
})
export class DayTopComponent implements OnInit {
  @Input() public firstDay: number;
  public selectedDay: number;
  public imgInfo: object [];
  public firstSelected: boolean;
  public videoIds: string[];

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.selectedDay=this.firstDay;
    this.videoIds = [
      'lAypgSkW4gY',
      '-eRYDQAznjU',
      'TrnQpR5LE_A',
      'PR-pLJAGbWk',
      'SpuGu875Hoc',
      'FvJwwFqlKpk',
      'Aneeb56c34U',
      'TmuODopm58M',
      'sg8o2UECmyU',
      'VzJm5jltIOI',
      '0zoO6eEdNvQ',
      'dFOc2s_3TZM',
      'odk63Ou2GHE',
      'BhQk4-zhNck',
      '1DVqouTb2UA',
      'PRGnSn1eFEw',
      'Agi3BvYv8LM',
      'b0DYlgO0k7U',
      'PDzrgQC1joI',
      'd2_wJzXf62U',
      'PBIMvy5whEE',
      'TTZaf7f6bbg'
    ];
    this.firstSelected = true;
    this.imgInfo = [
      { 
        image:"https://i.ibb.co/R4ZSm51/Chocolate-Fit.png",
      },
      { 
        image:"https://i.ibb.co/jvvn4c5/Protein-Active-Fit.png",
      },
      { 
        image:"https://i.ibb.co/k5LfjcH/Beauty-In.png",
      },
      

    ];
  }

  public changeSelectedDay(day: number): void {
    this.firstSelected = false;
    this.selectedDay = day;
  }

  public getUrl(day: number): string {
    return day > 3 ? `${day}/1` : `${day}`;
  }
}
