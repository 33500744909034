import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ServicioService {

  //colocamos todos los datos que vamos a compartir entre los componentes
  pesoObjetivo:number;
  pesoActual: number;
  altura:number;
  edad: number;
  imc:number;
  genero: number;
  actividad: number;
  imcTabla: string;
  imcIdeal:number;
  indiceAct:number;
  
  

  constructor() { }

  ngOnInit(){
        
      }

  

  


}
