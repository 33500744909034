import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InscribeteComponent } from './inscribete/inscribete.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { LoginComponent } from './login/login.component';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserComponent } from './user/user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { CodesComponent } from './admin/codes/codes.component';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import { PesoActualComponent } from './peso-actual/peso-actual.component';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatDialogModule } from '@angular/material';
import {QuestionsComponent} from './questions/questions.component';
import {AppButtonComponent} from './app-button/app-button.component';
import {QuestionsETComponent} from './questions-et/questions-et.component';
import {QHowYouFeelComponent} from './q-how-you-feel/q-how-you-feel.component';
import { PricesComponent } from './prices/prices.component';
import { TimerComponent } from './timer/timer.component';
import { FaqComponent } from './faq/faq.component';
import { LoadingComponent } from './loading/loading.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DaysComponent } from './days/days.component';
import {PlyrModule} from 'ngx-plyr';
import { MealComponent } from './meal/meal.component';
import { DayTopComponent } from './day-top/day-top.component';
import { DayBottomComponent } from './day-bottom/day-bottom.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { ExerciseInfoComponent } from './exercise-info/exercise-info.component';
import { FooterComponent } from './footer/footer.component';
import { DialogUserComponent } from './dialog-user/dialog-user.component';

@NgModule({
  declarations: [
    AppComponent,
    InscribeteComponent,
    NavBarComponent,
    LandingPageComponent,
    LoginComponent,
    UserComponent,
    ForgotPasswordComponent,
    BienvenidaComponent,
    CodesComponent,
    PesoActualComponent,
    DatosPersonalesComponent,
    QuestionsComponent,
    AppButtonComponent,
    QuestionsETComponent,
    QHowYouFeelComponent,
    PricesComponent,
    TimerComponent,
    FaqComponent,
    LoadingComponent,
    DaysComponent,
    MealComponent,
    DayTopComponent,
    DayBottomComponent,
    ExerciseComponent,
    ExerciseInfoComponent,
    FooterComponent,
    DialogUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MatDialogModule,
    AngularFirestoreModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireStorageModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    PlyrModule
  ],
  entryComponents:[
    DialogUserComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
