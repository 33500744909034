import { Component, OnInit } from '@angular/core';
import { ServicioService } from '../servicio.service';

@Component({
  selector: 'app-q-how-you-feel',
  templateUrl: './q-how-you-feel.component.html',
  styleUrls: ['./q-how-you-feel.component.css']
})
export class QHowYouFeelComponent implements OnInit {

  buttonConfig1 = {
    styles: {
      position: 'absolute',
      animation: 'none',
      left: '0%',
      top: '0%',
      right: '0%',
      bottom: '0%',
      width: '100px',
      height: '100px'
    },
    src: '../../assets/images/feel1'+this.servicio.genero+'.png',
  };

  buttonConfig2 = {
    styles: {
      position: 'absolute',
      animation: 'none',
      left: '0%',
      top: '0%',
      right: '0%',
      bottom: '0%',
      width: '100px',
      height: '100px'
    },
    src: '../../assets/images/feel2'+this.servicio.genero+'.png',
  };

  buttonConfig3 = {
    styles: {
      position: 'absolute',
      animation: 'none',
      left: '0%',
      top: '0%',
      right: '0%',
      bottom: '0%',
      width: '100px',
      height: '100px'
    },
    src: '../../assets/images/feel3'+this.servicio.genero+'.png',
  };

  constructor(private servicio: ServicioService) { }

  ngOnInit() {
  }

}
