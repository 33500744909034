import {Component, Input, OnInit} from '@angular/core';

export interface Explanation {
  image: string;
  text: string;
}

export interface Exercise {
  image: string;
  text: { title: string; text: string; }[];
  repetitions: number;
  showEvent: boolean;
  explanations: Explanation[];
}

export interface Exercises {
  exercise: Exercise[];
}

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit {
  @Input() public exercises: Exercise[];

  constructor() {
  }

  ngOnInit() {
  }
}
