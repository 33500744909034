import { Component, OnInit } from '@angular/core';
import { ServicioService } from '../servicio.service';

@Component({
  selector: 'app-questions-et',
  templateUrl: './questions-et.component.html',
  styleUrls: ['./questions-et.component.css']
})
export class QuestionsETComponent implements OnInit {

  constructor(private servicio: ServicioService) { }

  ngOnInit() {
    console.log(this.servicio.genero);
  }

  clickNinguna(){
    this.servicio.actividad=1.2;
    this.servicio.indiceAct=2;
  }

  clickLigero(){
    this.servicio.actividad=1.375;
    this.servicio.indiceAct=1;
  }

  clickModerado(){
    this.servicio.actividad=1.55;
    this.servicio.indiceAct=-1;
  }

  clickAlto(){
    this.servicio.actividad=1.725;
    this.servicio.indiceAct=-2;
  }

}
